<template>
  <div class="detail-info-content">
    <div class="content_one" style="margin-bottom: 10px;" id="m-cake">
      <div class="content_title">
        <span>核酸检测</span>
        <span>
          <el-button size="small" type="primary" v-btn-permission="'008099005'" @click="addRecord('核酸检测')">新增监测
          </el-button>
        </span>
      </div>
      <DetectionInfo :detects="detects" :regId="regId" frequency-name="核酸检测" />
    </div>

    <div class="content_one" style="margin-bottom: 10px;">
      <div class="content_title">
        <span>抗体检测</span>
        <span>
          <el-button size="small" type="primary" v-btn-permission="'008099006'" @click="addRecord('抗体检测')">新增监测
          </el-button>
        </span>
      </div>
      <DetectionInfo :detects="antibodyDetects" :regId="regId" frequency-name="抗体检测" />
    </div>

    <div class="content_one">
      <div class="content_title">体温测量</div>
      <table v-for="(i, v) in newDataArr" :key="v">
        <tr>
          <td style="width:140px" v-for="(i, v) in i" :key="v">{{ i.date }}</td>
        </tr>
        <tr>
          <td style="width:140px" v-for="(i, v) in i" :key="v">{{ i.tempValue }}</td>
        </tr>
      </table>
    </div>

    <div class="content_one">
      <div class="content_title">临床症状</div>
      <table v-for="(i, v) in newDataArr2" :key="v">
        <tr>
          <td style="width:140px" v-for="(i, v) in i" :key="v">{{ i.date }}</td>
        </tr>
        <tr>
          <td style="width:140px" v-for="(i, v) in i" :key="v">{{ i.symptom }}</td>
        </tr>
      </table>
    </div>

    <!--新增监测 弹窗-->
    <AddRecordDialog ref="AddRecordDialogRef" />
  </div>
</template>

<script>
import AddRecordDialog from './AddRecordDialog';
import DetectionInfo from './DetectionInfo';
import { getIsolateDetailByRegId } from '@/api/QuarantineRegistration/quarantineRegistrationpop'

export default {
  name: "CheckInfo",
  components: { AddRecordDialog, DetectionInfo },
  data() {
    return {
      roles: JSON.parse(localStorage.getItem("roles")),
      regId: "",  // 数据的id
      detects: [],  // 核酸检测数据
      antibodyDetects: [],  // 抗体检测数据
      newDataArr: [],  // 体温测量数据
      newDataArr2: [],  // 临床症状数据

    }
  },

  methods: {
    // 新增监测
    addRecord(type) {
      this.$refs.AddRecordDialogRef.show(this.regId, type)
    },

    // 隔离居民详情
    async getIsolateDetailByRegId(id) {
      console.log("🚀 ~ file: CheckInfo.vue ~ line 83 ~ getIsolateDetailByRegId ~ id", id)
      if (id.id) {
        this.regId = id.id;

      } else {

        this.regId = id;
      }

      let { data } = await getIsolateDetailByRegId({ id: this.regId });
      if (data.code == 200) {
        let { antibodyDetects, detects, symptoms, temps } = data.data;
        this.detects = detects;
        this.antibodyDetects = antibodyDetects;

        var arrLen = 7; //这里一行数组最多7个
        var newDataArr = [], newDataArr2 = [];
        for (var i = 0; i < temps.length; i += arrLen) {
          newDataArr.push(temps.slice(i, i + arrLen));
        }
        this.newDataArr = newDataArr;

        // 临床症状
        for (var i = 0; i < symptoms.length; i += arrLen) {
          newDataArr2.push(symptoms.slice(i, i + arrLen));
        }
        this.newDataArr2 = newDataArr2;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.detail-info-content {
  width: 100%;
  background-color: #fff;
  padding: 0 14px;
  box-sizing: border-box;

  .content_one {
    margin-bottom: 20px;

    tr {
      border: 1px solid #ccc;
    }

    td {
      width: 158px;
      height: 45px;
      opacity: 1;
      background: #f5f5f5;
      font-weight: bold;
      text-align: center;
      border: 1px solid #ccc;
    }

    tr:nth-child(2n) {
      td {
        background: #ffff;
      }
    }

    table {
      border-collapse: collapse;
    }
  }

  .content_title {
    padding: 20px 0;
    color: #333;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-left: 14px;

    &:after {
      content: '';
      width: 5px;
      height: 23px;
      background: #409EFF;
      position: absolute;
      top: 22px;
      left: -12px;
    }
  }
}
</style>
